<template>
  <div class="result-page">
    <div v-if="!errmsgShow" id="posterHtml" class="imgBox">
      <!-- 背景图 -->
      <img id="bg-img" class="bg-img" style="max-width:420px" src="../assets/resultsImg.jpg" alt="" />

      <!-- 头像昵称 -->
      <div class="profile">
        <div class="headimgurl" style="width:40px;height:40px">
          <img
            :src="user.headimgurl"
            alt=""
          >
        </div>
        <div class="nickname" style="font-size:14px;margin-left:10px">{{ user.nickname }}
        </div>
      </div>

      <!-- 个人能力分析echarts图 -->
      <div class="chart-container">
        <div id="myChart" style="width:310px;height:300px"></div>
        <!-- <div id="myChart" style="width:280px;height:270px"></div> -->
      </div>

      <!-- 评语 -->
      <div id="remark" class="remark remark-box" style="font-size: 15px;padding: 0 20px 0 33px;line-height: 1.6;">
        <!-- <div>恭喜你达到了<span class="key-word" style="font-size:15px">{{userResults.level}}</span>级别，超越了<span  class="key-word" style="font-size:15px">{{userResults.per}}%</span>的人！</div>   -->
        <div>恭喜你在行业排位中达到了<span class="key-word" >{{userResults.level}}</span>级别</div>
        <!-- <span>你在自动化测试能力，持续集成/持续交付/DevOps方面表现非常优秀，是行业不可多得的人才</span>  -->
        <div>在<span class="key-word" >{{userResults.first}}</span>方向，超越了<span  class="key-word" >{{userResults.first_percent}}%</span>的人</div> 
        <div>在<span class="key-word" >{{userResults.second}}</span>方向，超越了<span  class="key-word" >{{userResults.second_percent}}%</span>的人</div> 
        <div>在霍格沃兹学院中，可能属于<span class="key-word" >{{userResults.college}}</span>分院</div>
        <!-- <div v-for="(item,index) in userResults.comment" :key="index" v-html="item"></div> -->
      </div>
      
      <!-- 底部二维码 -->
      <div class="qrcode" >
        <!-- <vue-qr class="qrcode-img" :text="user.front_qrcode" :size="100" :margin="2"></vue-qr> -->
        <vue-qr class="qrcode-img" :text="userResults.url" :size="70" :margin="2"></vue-qr>
      </div>
    </div>

    <Loading :loading="loading"></Loading>

    <!-- errmsg -->
    <div class="tip" v-if="errmsgShow" style="width:280px;height:120px;font-size:18px;border-radius: 10px;">
      <div class="tip-text" style="height:80px;border-bottom: 1px solid #eee;">
        <span ><b>{{errmsg}}</b></span>
      </div>
    </div>

    <div class="button-group" v-if="!errmsgShow">
      <!-- 查看二维码按钮 -->
      <div class=" button" >
        <div style="font-size:16px" @click="dialog = true">预约行业调查报告</div>
      </div>

      <!-- 领取红包跳转按钮 -->
      <div class="menu button"  style="margin-left:30px">
        <div style="font-size:16px" @click="getMoney">领取调研红包</div>
      </div>
    </div>

    <v-dialog
        v-model="dialog"
        width="290"
    >
      <v-card>
        <v-card-title class="" style="font-size:16px;display:flex;justify-content: center;align-items: center;line-height:50px">
           扫码添加小助手，回复：行业调查报告
        </v-card-title>
        <v-card-text style="display:flex;justify-content: center;align-items: center;">
           <!-- 随机二维码 将api地址直接放到src-->
          <img style="width:120px;height:120px;" src="https://qrcode.ceba.ceshiren.com/qrcode?project=ceping&pattern=inspect&show=random" alt="">
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import html2canvas from "html2canvas";
import VueQr from "vue-qr";
// import axios from '../api/http'
import request from 'axios'
export default {
  name: 'Profile',
  components: {
    VueQr,
  },
  data() {
    return {
      user: {
        headimgurl: 'https://ceshiren.com/uploads/default/original/1X/809c63f904a37bc0c6f029bbaf4903c27f03ea8a.png',
        nickname: '霍格沃兹测试学院',
      },
      userResults: {
        per: '60',
        level: '钻石',
        comment: ['你在自动化测试能力，持续交付/DevOps方面表现非常优秀，是行业不可多得的人才', '你在测试开发架构能力方面还有很大的发展潜力'],
        score: {},
        url: 'https://wechat.ceba.ceshiren.com/qrcode?url=wechat&state=om73e1RkYMpHdrDOsoNu17V7O-yA',
      },
      loading: false,
      fullWidth: document.documentElement.clientWidth,
      dialog: false,
      origin_: '',
      errmsg: '',
      errmsgShow: false,
    }
  },
  created() {
    this.origin_ = location.origin
    this.loading = true
    this.getProfileInfo()
    
  },
  mounted() {
    // this.drawLine()
  },
  methods: {
    //获取用户头像昵称
    getProfileInfo() {
      request.get(`${this.origin_}/sso/userinfo`)
        .then(res => {
          console.log(res);
          if (res.data.nickname) {
            // 赋值
            this.user.nickname = res.data.nickname
            this.user.headimgurl = res.data.headimgurl
            this.getUserData()
          }
        })
        .catch(()=>{
          this.getUserData()
        })
    },
    //获取用户数据
    getUserData() {
      let params = this.$route.query.x_field_1
      request.get(`${this.origin_}/backend/evaluation/get_report/2022_evaluation_ir?unionid=${params}`)
        .then(res => {
          console.log(res);
          if (res.data.code === 0) {
            //获取成功
            if (res.data.data.level)  this.userResults = res.data.data
            this.$nextTick(() => {
              this.drawLine()
            })
          } else {
            this.errmsg = res.data.errmsg
            this.errmsgShow = true
            this.loading = false
          }
        })
        .catch(() => {
          // 开发测试时调整页面使用
          this.$nextTick(() => {
            this.drawLine()
          })
        })
    },

    setFont(arr, size) {
      for (let i = 0; i < arr.length; i++) {
        arr[i].style.cssText = `font-size:${size}px;`
      }
    },

    getMoney(){
      location.href = 'https://jinshuju.net/f/halTmQ'
    },


    //绘制echarts图
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let imgWidth = window.innerWidth
      // let arr = document.querySelectorAll('.key-word')
      let remark = document.getElementById("remark")
      console.log(imgWidth);
      if (imgWidth <= 360) {
        remark.style.cssText = 'font-size: 14px;padding: 0 20px 0 33px;line-height: 1.6'
      }
      if (imgWidth <= 340) {
        remark.style.cssText = 'font-size: 13px;padding: 0 20px 0 33px;line-height: 1.6'
      }
      if (imgWidth <= 320) {
        remark.style.cssText = 'font-size: 12px;padding: 0 20px 0 33px;line-height: 1.6'
        document.querySelector('.chart-container').style.top = '25%'
      }
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      myChart.on('finished', () => {
        console.log('echarts绘制完成');
        setTimeout(this.saveHtml2Image, 0);
      })
      // 绘制图表
      let radius = 110
      if (this.fullWidth >= 320) radius = 80
      if (this.fullWidth >= 340) radius = 90
      if (this.fullWidth >= 360) radius = 100
      if (this.fullWidth >= 375) radius = 110

      let scoreData = this.userResults.score

      let option = {
        title: {
          // text: '基础雷达图'
        },
        color: ['#87a4c4', '#87a4c4'],
        // tooltip: {}, 
        legend: {
          show: false,
          data: ['预算分配', '实际开销'],
          selectedMode: false, // 是否允许点击
        },
        radar: {
          // shape: 'circle',
          radius: radius,
          nameGap: 3,
          // 指示器名称和指示器轴的距离。[ default: 15 ]
          splitNumber: 5,
          // (这里是圆的环数)指示器轴的分割段数。[ default: 5 ]
          name: {
            textStyle: {
              color: '#000',
              // backgroundColor: '#999',
              // borderRadius: 3,
              padding: [3, 5]
            },
            formatter: function (text) {
              var strlength = text.length;
              if (text === '持续交付/DevOps') {
                return text
              }
              if (strlength % 2 == 1) {
                text = text.replace(/\S{4}/g, function (match) {
                  return match + '\n'
                })
              } else {
                text = text.replace(/\S{4}/g, function (match) {
                  return match + '\n'
                })
                strlength = text.length;
                text = text.substring(0, strlength - 1);
              }
              return text
            },
          },

          splitArea: {
            areaStyle: {
              // color: ['#fff'], //圆环区域颜色
              // color: ['#4a657a', '#308e92', '#b1cfa5', '#f5d69f', '#f5898b', ]
              // color: ['#d7f0f0', '#94dee2', '#5ac3c6','#6ccfd2', '#2a8f92', ]
              color: [
                'rgba(51,142,148,1)',
                'rgba(51,142,148,.8)',
                'rgba(51,142,148,.6)',
                'rgba(51,142,148,.4)',
                'rgba(51,142,148,.2)',
              ]
              // color: ['rgba(11,89,140,1)',
              //   'rgba(11,89,140,0.8)',
              //   'rgba(11,89,140,0.6)',
              //   'rgba(11,89,140,0.4)',
              //   'rgba(11,89,140,0.2)'],
              // shadowColor: 'aqua', // 圆颜色
              // shadowBlur: 10
            }
          },
          //    splitLine: {
          //      lineStyle: {
          //          color: ['#000'] //网格的颜色
          //      },
          //  },

          indicator: [
            { name: '项目经验', max: 100 },
            { name: '自动化测试能力', max: 100 },
            { name: '测试开发架构能力', max: 100 },
            { name: '持续交付/DevOps', max: 100 },
            { name: '开发能力', max: 100 },
            { name: '影响力', max: 100 }
          ]
        },
        series: [{
          // name: '预算 vs 开销（Budget vs spending）',
          type: 'radar',
          // areaStyle: {normal: {}},
          data: [
            {
              //value: [80, undefined, 75, 100, 50, 60],
              value: scoreData['项目经验'] || scoreData['项目经验'] == 0 ? [scoreData['项目经验'], scoreData['自动化测试能力'], scoreData['测试开发架构能力'], scoreData['持续交付/DevOps'], scoreData['开发能力'], scoreData['影响力']] : [40, 30, 70, 90, 50, 90],
              name: '实际开销',
              // areaStyle: {
              //   // color: '#87a4c4',
              //   color: '#d3dfed',
              // },
              itemStyle: {
                normal: {
                  color: '#ff0000',
                },
              },
            },
          ],
        }]
      }
      myChart.setOption(option);
    },

    saveHtml2Image() {
      window.pageYoffset = 0;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      if (document.getElementById('profile-poster-Img')) {
        document.getElementById('profile-poster-Img').style.display = "block"
        return
      }
      html2canvas(document.querySelector('#posterHtml'), {
        useCORS: true,
        allowTaint: true,
      }).then(async canvas => {
        const img = document.createElement("img");
        img.src = canvas.toDataURL("image/jpeg", 0.9)
        document.body.appendChild(img);
        img.style.cssText =
          "width:100%;height:100%;position:absolute;top:0;left:0;right:0;bottom:0;opacity:0;z-index:89;";
        img.id = "profile-poster-Img"
        this.loading = false
      });
    },

  },
}
</script>

<style  scoped>
.result-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgBox {
  position: relative;
  display: inline-block;
  /* line-height: 0; */
}
.bg-img {
  width: 100%;
  object-fit: contain;
}
.profile {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 1.5%;
  left: 5%;
}
/* 头像 */
.headimgurl {
  border-radius: 50%;
  overflow: hidden;
  background-color: #fff;
}
/* 昵称 */
.nickname {
  color: #fff;
}
.headimgurl img {
  width: 100%;
  height: 100%;
}
.chart-container {
  width: 100%;
  position: absolute;
  top: 28%;
  display: flex;
  justify-content: center;
}
#myChart {
  /* width: 8.8rem; */
  /* height: 7.6rem;
  width: 100vw; */
  /* width: 300px;
  height: 340px; */
  /* background-color: white; */
}
.remark {
  width: 100%;
  position: absolute;
  top: 69%;
  text-align: center;
  line-height: 1;
  display: flex;
  justify-content: center;
}
.remark-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.remark-box > div {
  text-align: start;
  display: block;
  word-break: break-all;
}
.remark-box > span {
  text-align: start;
  word-break: break-all;
}
.qrcode {
  position: absolute;
  right: 7%;
  bottom: 2.5%;
}
.button-group{
  width: 100%;
  display: flex;
  justify-content: center;
}

.button {
  display: block;
  margin: 10px 0;
  line-height: 1.8;
  padding: 0 8px;
  background-color: #409eff;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  z-index: 90;
}
.tip {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  z-index: 99;
  line-height: 1;
}
.tip-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tip-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tip-button div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tip-button div:nth-child(1) {
  border-right: 1px solid #eee;
}
.key-word {
  font-weight: 600;
  color: red;
}
/* @media screen and (max-width: 360px) {
  .remark {
    font-size: 14px;
  }
}
@media screen and (max-width: 375px) {
  .remark {
     font-size: 16px;
  }
} */
.v-dialog > .v-card > .v-card__title {
  padding: 0;
}
.v-dialog > .v-card > .v-card__text {
  padding: 0;
}
.v-card__title + .v-card__subtitle,
.v-card__title + .v-card__text {
  padding: 0;
}
</style>